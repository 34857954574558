main {
  font-family: 'Figtree', sans-serif;
  width: 100%;
  display: grid;
  justify-items: center;
}

img {
  height: 200px;
}

div {
  width: 100%;
  text-align: center;
}

h2 {
  font-size: 1.5rem;
  font-weight: normal;
}
